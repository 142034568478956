import React, { useEffect, useRef, useState } from "react";
import titleimg from "../../../../assets/images/worldbook/21.png";
import ImageCropper from "./image-cropper/ImageCropper";
import mixins from "../../../../app-services/mixins";
import Alertmodal from "../Alertmodal";
import Nitification from "./Nitification";

const Uploadthree = (props) => {
  const {
    formdata,
    images,
    setImages,
    imagesassets,
    setImagesassets,
    originalImage,
    setOriginalimage,
    handlePreinfoSave = () => {},
    handlebackbtn = () => {},
  } = props;

  const [alert, setAlert] = useState({
    image_four: null,
    image_five: null,
  });
  const nameRef = useRef();
  const [line1, setLine1] = useState(null);
  const [line2, setLine2] = useState(null);
  const [image1, setImage1] = useState({});
  const [image2, setImage2] = useState({});
  const [newalert, setNewalert] = useState(null);

  const handlesubmit = (e) => {
    e.preventDefault();
    if (!image1?.base64) {
      setAlert({
        ...alert,
        image_four: "Field is required.",
      });
    } else if (!image2?.base64) {
      setAlert({
        ...alert,
        image_five: "Field is required.",
      });
    } else {
      setImages({
        ...images,
        image_four: image1?.file,
        image_five: image2?.file,
      });
      setImagesassets({
        ...imagesassets,
        image_four: image1?.base64,
        image_five: image2?.base64,
      });
      handlePreinfoSave();
    }
  };

  const handleCropCompletefour = (base64, file) => {
    mixins.compressImageToTargetSize(base64).then((res) => {
      setImage1({
        base64: res,
        file: file,
      });
    });
  };
  const handleCropCompletefive = (base64, file) => {
    mixins.compressImageToTargetSize(base64).then((res) => {
      setImage2({
        base64: res,
        file: file,
      });
    });
  };
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert({
          image_four: null,
          image_five: null,
        });
      }, 2000);
    }
  }, [alert]);
  useEffect(() => {
    if (imagesassets?.image_four) {
      setImage1({
        base64: imagesassets?.image_four,
        file: images?.image_four,
      });
    }
    if (imagesassets?.image_five) {
      setImage2({
        base64: imagesassets?.image_five,
        file: images?.image_five,
      });
    }
    if (formdata?.year) {
      let parray = mixins.extractParagraphs(formdata.year);
      setLine1(parray?.[0] || "");
      setLine2(parray?.[1] || "");
    }
  }, []);
  return (
    <div id="msform">
      <div className="content">
        <div className="container">
          <div className="row">
          <div className="col-md-12">
              <Nitification />
            </div>
            <div className="col-md-6">
              <div className="picture">
                {image1?.base64 && (
                  <img src={image1?.base64} className="uploaded_image_four" />
                )}
                {image2?.base64 && (
                  <img src={image2?.base64} className="uploaded_image_five" />
                )}
                <img
                  src={
                    "https://e-books-customization.s3.ap-southeast-1.amazonaws.com/e-book-api/thisisyourworld/21.png"
                  }
                  className="main_image_one"
                  alt=""
                />
                <div className="modal_year_container">
                  <div className="year_class">
                    <span id="custom_year">
                      {line1 || `Custom Text Here`} <br />
                      {line2 || `eg Graduating Class of 202X`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="rt-content">
                <div className="mb-2">
                  <h4>PHOTO 4</h4>
                  <p>Upload a photo of an adventure or a group.</p>
                  <input
                    ref={nameRef}
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    className="m-0 image-upload"
                    onChange={(e) => {
                      let pass = mixins.filefivemb(e);
                      if (pass?.status) {
                        const file = e.target.files[0];
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          const base64String = reader.result;
                          setOriginalimage({
                            ...originalImage,
                            image_four: {
                              base64: base64String,
                              file: file,
                            },
                          });
                          handleCropCompletefour(base64String, file);
                        };

                        reader.readAsDataURL(file);
                      } else {
                        setNewalert(pass.message);
                      }
                    }}
                  />
                  {alert?.image_four && (
                    <span
                      style={{
                        color: "red",
                      }}
                      className="mb-2"
                    >
                      {alert?.image_four}
                    </span>
                  )}
                  {originalImage?.image_four?.base64 && (
                    <div className="row my-2">
                      <div className="col-md-12">
                        <ImageCropper
                          image={originalImage?.image_four?.base64}
                          onCropComplete={handleCropCompletefour}
                          ratio={8 / 6}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <hr />
                <div>
                  <h4>PHOTO 5</h4>
                  <p>Upload a photo of a place or event.</p>
                  <input
                    ref={nameRef}
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    className="m-0 image-upload"
                    onChange={(e) => {
                      let pass = mixins.filefivemb(e);
                      if (pass?.status) {
                        const file = e.target.files[0];
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          const base64String = reader.result;
                          setOriginalimage({
                            ...originalImage,
                            image_five: {
                              base64: base64String,
                              file: file,
                            },
                          });
                          handleCropCompletefive(base64String, file);
                          setImage2({
                            base64: base64String,
                            file: file,
                          });
                        };

                        reader.readAsDataURL(file);
                      } else {
                        setNewalert(pass.message);
                      }
                    }}
                  />
                  {alert?.image_five && (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {alert?.image_five}
                    </span>
                  )}
                  {originalImage?.image_five?.base64 && (
                    <div className="row my-2">
                      <div className="col-md-12">
                        <ImageCropper
                          image={originalImage?.image_five?.base64}
                          onCropComplete={handleCropCompletefive}
                          ratio={1}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="save-btn">
                  <button
                    style={{
                      display: "none",
                    }}
                    id="action-btn"
                    className="action-button"
                    onClick={handlesubmit}
                  >
                    Save & Back to List
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Alertmodal
        open={newalert}
        handleClose={() => {
          setNewalert(null);
        }}
      />
    </div>
  );
};

export default connect(Uploadthree);
