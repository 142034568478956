import React, { useState } from "react";
import "./modal.css";
import { Modal } from "react-bootstrap";

const Leftalert = ({ open, handleyess, handleno }) => {
  return (
    <Modal
      size="lg"
      show={open}
      //onHide={handleClose}
      animation={true}
      className="alert-modal"
      style={{
        zIndex: "999999",
        marginTop: "30px",
      }}
      centered
    >
      <Modal.Header>Plotterbox</Modal.Header>
      <Modal.Body>
        <p
          className="text-start"
          style={{
            fontWeight: "600",
          }}
        >
          You have unsaved changes to your book. If you leave this page or close
          this tab, your changes will be lost.
        </p>
        <p
          className="text-start"
          style={{
            fontWeight: "600",
          }}
        >
          If you wish to save your changes, stay on this page and click Save &
          Add to Cart in the preview. Then simply register or log in, and your
          book will be saved in your cart.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex align-items-center justify-content-center ">
          <button
            onClick={() => {
              handleyess();
            }}
            className="action-button-previous"
            style={{
              background: "#50a1a4",
              border: "none",
              color: "#fff",
              fontSize: "15px",
              padding: "6px 12px",
              marginRight: "10px",
            }}
          >
            Leave
          </button>
          <button
            onClick={() => {
              handleno();
            }}
            className="action-button-previous"
            style={{
              background: "#fff",
              border: "#50a1a4",
              color: "#50a1a4",
              fontSize: "15px",
              padding: "6px 12px",
            }}
          >
            Stay
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default Leftalert;
