import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Leftalert from "./popups/Leftalert";

const Savedbooklist = (props) => {
  const { id, customBookId } = useParams();
  const {
    savedBook,
    deleteCartitem = () => {},
    formdata,
    imagesassets,
  } = props;
  const [showalert, setShowalert] = useState(false);
  const [movedata, setMovedata] = useState({});
  const history = useHistory();
  const moveedit = (item) => {
    setShowalert(false);
    let { customizedId, customBookType } =
      item?.customBook || movedata.customBook;
    let { customBookId, bookId } = item || movedata;
    history.push(`/book/${"world-book"}/${bookId}/${customizedId}`);
  };

  const showalertfunc = (item) => {
    if (customBookId) {
      if (
        imagesassets?.image_one ||
        imagesassets?.image_two ||
        imagesassets?.image_three ||
        imagesassets?.image_four ||
        imagesassets?.image_five ||
        imagesassets?.image_six ||
        imagesassets?.image_seven ||
        imagesassets?.image_eight ||
        imagesassets?.image_nine
      ) {
        setShowalert(true);
        setMovedata(item);
      } else {
        moveedit(item);
      }
    } else {
      if (
        imagesassets?.image_one ||
        imagesassets?.image_two ||
        imagesassets?.image_three ||
        imagesassets?.image_four ||
        imagesassets?.image_five ||
        imagesassets?.image_six ||
        imagesassets?.image_seven ||
        imagesassets?.image_eight ||
        imagesassets?.image_nine ||
        formdata?.name
      ) {
        setShowalert(true);
        setMovedata(item);
      } else {
        movedata(item);
      }
    }
  };

  return (
    <div className="container world-book-save">
      <div className="row">
        <div className="col-md-12">
          {savedBook && props.store.user && savedBook.length >= 1 && (
            <>
              <div className="title">Saved Books</div>

              <div className="save-later-div">
                <div className="row">
                  {savedBook &&
                    props.store.user &&
                    savedBook.length >= 1 &&
                    savedBook.map((item, index) => {
                      return (
                        <div className="col-md-2">
                          <div className="ProductCard row no-gutters card">
                            {/* { console.log(item) } */}
                            <div className="image-container">
                              <img
                                src={item?.Books?.fileData?.filePath}
                                className="cart-image-container"
                              />
                              {/* <img src={search} className="search-image-container" /> */}
                            </div>
                            <div className="col">
                              <div className="button-container">
                                <button
                                  className="delete-button "
                                  onClick={() => deleteCartitem(item.id)}
                                  disabled={
                                    item?.customBook?.customizedId ==
                                    customBookId
                                      ? true
                                      : false
                                  }
                                >
                                  Remove book
                                </button>
                                <button
                                  className="save-button"
                                  onClick={() => {
                                    showalertfunc(item);
                                  }}
                                  disabled={
                                    item?.customBook?.customizedId ==
                                    customBookId
                                      ? true
                                      : false
                                  }
                                >
                                  Edit Book
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Leftalert
        open={showalert}
        handleyess={moveedit}
        handleno={() => {
          setShowalert(false);
        }}
      />
    </div>
  );
};

export default connect(Savedbooklist);
