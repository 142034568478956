import React from "react";
import checkimage from "../../../assets/images/worldbook/check.svg";
import removeimg from "../../../assets/images/worldbook/remove.png";

const Imageupload = (props) => {
  const {
    imagesassets,
    formdata,
    handleClick = () => {},
    handlePreinfoSave = () => {},
  } = props;
  return (
    <div id="msform" className="image-upload-container">
      <fieldset className="box-preview">
        <div className="form-card">
          <div className="row m-0">
            <div className="col-12 canvas-info">
              <p>Upload photos that will appear on these pages!</p>
              <p>
                We’ve made some suggestions. But they can be images of loved
                ones, pets, favourite things, group photos, best friends,
                events, school…
              </p>
              <p>
                For best results, use photos of at least 300 DPI. Photos of
                lower quality will be less clear when printed. Maximum file size
                5 MB.
              </p>
            </div>
            <div className="levelForm">
              <div className="LevelForm-list three-row">
                <div className="form-list-item">
                  <div
                    className="boxInner"
                    onClick={() => {
                      handleClick("imageone");
                    }}
                  >
                    <h4>1. Something a little silly</h4>
                    <div className="item-content">
                      <img
                        src={imagesassets?.image_one ? checkimage : removeimg}
                        alt=""
                      />
                      <p>Photo 1</p>
                    </div>
                  </div>
                </div>
                <div className="form-list-item">
                  <div
                    className="boxInner"
                    onClick={() => {
                      handleClick("imagetwo");
                    }}
                  >
                    <h4>2. Treasured moments</h4>
                    <div className="item-content">
                      <img
                        src={imagesassets?.image_two ? checkimage : removeimg}
                        alt=""
                      />
                      <p>Photo 2</p>
                    </div>
                    <div className="item-content">
                      <img
                        src={imagesassets?.image_three ? checkimage : removeimg}
                        alt=""
                      />
                      <p>Photo 3</p>
                    </div>
                  </div>
                </div>
                <div className="form-list-item">
                  <div
                    className="boxInner"
                    onClick={() => {
                      handleClick("imagethree");
                    }}
                  >
                    <h4>3. Adventures and events</h4>
                    <div className="item-content">
                      <img
                        src={imagesassets?.image_four ? checkimage : removeimg}
                        alt=""
                      />
                      <p>Photo 4</p>
                    </div>
                    <div className="item-content">
                      <img
                        src={imagesassets?.image_five ? checkimage : removeimg}
                        alt=""
                      />
                      <p>Photo 5</p>
                    </div>
                  </div>
                </div>
                <div className="form-list-item">
                  <div
                    className="boxInner"
                    onClick={() => {
                      handleClick("imagefour");
                    }}
                  >
                    <h4>4. Friends and loved ones</h4>
                    <div className="item-content">
                      <img
                        src={imagesassets?.image_six ? checkimage : removeimg}
                        alt=""
                      />
                      <p>Photo 6</p>
                    </div>
                    <div className="item-content">
                      <img
                        src={imagesassets?.image_seven ? checkimage : removeimg}
                        alt=""
                      />
                      <p>Photo 7</p>
                    </div>
                  </div>
                </div>
                <div className="form-list-item">
                  <div
                    className="boxInner"
                    onClick={() => {
                      handleClick("imagefive");
                    }}
                  >
                    <h4>5. More memories</h4>
                    <div className="item-content">
                      <img
                        src={imagesassets?.image_eight ? checkimage : removeimg}
                        alt=""
                      />
                      <p>Photo 8</p>
                    </div>
                    <div className="item-content">
                      <img
                        src={imagesassets?.image_nine ? checkimage : removeimg}
                        alt=""
                      />
                      <p>Photo 9</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default connect(Imageupload);
