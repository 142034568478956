import axios from "axios";

export default {
  // GET Character Assets
  getCharacterAssets() {
    return this.handelAlert((res, rej, loadObj) => {
      axios
        .get(`${base}/api/characterAssets/list`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((d) => {
          res(d.data);
        })
        .catch(rej);
    }, false);
  },
  // GET Character Assets
  getAboutusPage() {
    return this.handelAlert((res, rej, loadObj) => {
      axios
        .get(`${base}/api/aboutUs/listAboutUs`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((d) => {
          res(d.data);
        })
        .catch(rej);
    }, false);
  },

  // GET static block urls
  getStaticBlocks() {
    return this.handelAlert((res, rej, loadObj) => {
      axios
        .get(`${base}/api/staticBlock/list`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((d) => {
          res(d.data);
        })
        .catch(rej);
    }, false);
  },
  // GET subscription list
  getSubscriptionPlanList() {
    return this.handelAlert((res, rej, loadObj) => {
      axios
        .get(`${base}/api/subscriptionCategory/listActive`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((d) => {
          res(d.data);
        })
        .catch(rej);
    }, false);
  },
  // POST All city by
  subscriptioncreate(data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .post(`${base}/api/userSubscription/create`, data, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch((rej) => {
          res(rej.response.data);
        });
    }, false);
  },
  // POST All city by
  subscriptionrenewal(data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .post(`${base}/api/userSubscription/renew`, data, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch((rej) => {
          res(rej.response.data);
        });
    }, false);
  },

  //create referral
  referyourfriend(data) {
    return this.handelAlert((res, rej, loadobj) => {
      axios
        .post(`${base}/api/referral/create`, data, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((response) => {
          res(response.data);
        })
        .catch((rej) => {
          res(rej.response.data);
        });
    }, false);
  },
  getcouponcode(couponcode) {
    return this.handelAlert(
      (res, rej, loadobj) => {
        axios
          .get(
            `${base}/api/shippingAddress/getCouponInfo?coupon_code=${couponcode}`,
            {
              headers: {
                Authorization: `Bearer ${
                  localStorage.getItem("token")
                    ? localStorage.getItem("token")
                    : token
                }`,
              },
            }
          )
          .then((response) => {
            //console.log("response", response);
            res(response.data);
          })
          .catch((rej) => {
            res(rej.response.data);
          });
      },
      true,
      ""
    );
  },
};
