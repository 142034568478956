import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const useBlocker = (blockCondition, message) => {
  const history = useHistory();
  const [nextLocation, setNextLocation] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);

  useEffect(() => {
    if (!blockCondition) return;

    // Block navigation within the app
    const unblock = history.block((location) => {
      if (isConfirmed) return true; // Allow navigation if confirmed

      setNextLocation(location); // Store pending navigation
      return false; // Block navigation
    });

    // Block browser tab close or refresh
    const handleBeforeUnload = (event) => {
      if (blockCondition) {
        event.preventDefault();
        event.returnValue = message; // Show warning on tab close
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      unblock();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [blockCondition, history, isConfirmed, message]);

  const confirmNavigation = () => {
    setIsConfirmed(true);
    if (nextLocation) {
      history.push(nextLocation.pathname); // Proceed to stored location
    }
  };

  const cancelNavigation = () => {
    setNextLocation(null); // Hide popup when cancelled
  };

  return { confirmNavigation, cancelNavigation, nextLocation };
};

export default useBlocker;
