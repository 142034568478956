import React, { useEffect, useRef, useState } from "react";
import titleimg from "../../../../assets/images/worldbook/book-title.png";
import mixins from "../../../../app-services/mixins";

const Booktitle = (props) => {
  const {
    formdata,
    setFormdata,
    handlePreinfoSave = () => {},
    handlebackbtn = () => {},
    handlePreinfoSavesecond = {},
  } = props;
  const imageRef = useRef();
  const [fontsize, setFontsize] = useState("10");
  const [alert, setAlert] = useState(null);
  const [line1, setLine1] = useState();
  const [line2, setLine2] = useState();
  const [limit, setLimit] = useState(11);
  const [limit2, setLimit2] = useState(11);
  const nameRef = useRef();
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const handlesubmit = (e) => {
    e.preventDefault();
    if (!line1 || line1?.trim() == "") {
      setAlert("Field is required.");
      nameRef.current.focus();
    } else {
      let book_title = `<div>${line1 ? `<p>${line1}</p>` : ""}${
        line2 ? `<p>${line2}</p>` : ""
      }</div>`;
      let newformdata = { ...formdata, book_title: book_title };
      handlePreinfoSavesecond(newformdata);
    }
  };
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(null);
      }, 1100);
    }
  }, [alert]);
  useEffect(() => {
    if (line1?.trim()) {
      setLimit(11 - line1?.length || 0);
    } else {
      setLimit(11);
    }
    if (line2?.trim()) {
      setLimit2(11 - line2?.length || 0);
    } else {
      setLimit2(11);
    }
  }, [line1, line2]);

  useEffect(() => {
    if (formdata?.book_title) {
      let parray = mixins.extractParagraphs(formdata.book_title);
      setLine1(parray?.[0] || "");
      setLine2(parray?.[1] || "");
    }
  }, [formdata?.book_title]);

  const setupfontside = () => {
    let size = imageRef.current?.clientWidth;
    if (size) {
      console.log((size * 0.015).toFixed(0));
      setFontsize(12);
    }
  };

  useEffect(() => {
    const handleLoad = () => {
      setupfontside();
      setIsPageLoaded(true);
    };

    if (document.readyState === "complete") {
      setIsPageLoaded(true);
    } else {
      window.addEventListener("load", handleLoad);
      return () => window.removeEventListener("load", handleLoad);
    }
  }, []);

  return (
    <div id="msform">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div ref={imageRef} className="picture">
                <img
                  src={
                    "https://e-books-customization.s3.ap-southeast-1.amazonaws.com/e-book-api/thisisyourworld/final/9.png"
                  }
                  alt=""
                />
                <div
                  class="book_title"
                  style={{
                    fontSize: `${fontsize}px !important`,
                  }}
                >
                  <p>{line1 || "Line 1"}</p>
                  <p>{line2 || "Line 2"}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="rt-content">
                <h4>What book is the bunny reading? </h4>
                <p>
                  Name a favourite book or create your own book title here!{" "}
                </p>
                <div className="form-group position-relative my-2">
                  <input
                    ref={nameRef}
                    type="text"
                    placeholder="Please enter book title Line 1"
                    className="m-0"
                    maxLength={11}
                    value={line1 || ""}
                    onChange={(e) => {
                      mixins.preventEmoji(e);
                      setLine1(e.target.value);
                    }}
                  />
                  <span className="limit">{limit}</span>
                  {alert && (
                    <span
                      style={{
                        color: "red",
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        fontSize: "12px",
                      }}
                    >
                      {alert}
                    </span>
                  )}
                </div>
                <div className="form-group position-relative my-2">
                  <input
                    type="text"
                    placeholder="Please enter book title Line 2"
                    className="m-0"
                    maxLength={11}
                    value={line2 || ""}
                    onChange={(e) => {
                      mixins.preventEmoji(e);
                      setLine2(e.target.value);
                    }}
                  />
                  <span className="limit">{limit2}</span>
                </div>

                <div className="save-btn">
                  <button
                    style={{
                      display: "none",
                    }}
                    id="action-btn"
                    className="action-button"
                    onClick={handlesubmit}
                  >
                    Save & Back to List
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(Booktitle);
