import React, { useEffect, useState } from "react";
import Worldbookview from "./Worldbookview";
import "./HandPrint.css";
import Stepbar from "./Stepbar";
import Modelwrapper from "./popups/Modelwrapper";
import Firstname from "./popups/Firstname";
import Customisebook from "./popups/Customisebook";
import Booktitle from "./popups/customisebook/Booktitle";
import Yearset from "./popups/customisebook/Yearset";
import Favouriteplace from "./popups/customisebook/Favouriteplace";
import Menuitems from "./popups/customisebook/Menuitems";
import Inepirationalmessage from "./popups/customisebook/Inepirationalmessage";
import Imageupload from "./popups/Imageupload";
import Uploadone from "./popups/Imageupload/Uploadone";
import Uploadtwo from "./popups/Imageupload/Uploadtwo";
import Uploadthree from "./popups/Imageupload/Uploadthree";
import Uploadfour from "./popups/Imageupload/Uploadfour";
import Uploadfive from "./popups/Imageupload/Uploadfive";
import { useHistory, Link } from "react-router-dom";
import Dedication from "./popups/customisebook/Dedication";
// Pages
import Coverone from "./txtpages/coverone.txt";
import Covertwo from "./txtpages/covertwo.txt";
import Pageone from "./txtpages/Page1.txt";
import Pagetwo from "./txtpages/Page2.txt";
import Pagethree from "./txtpages/Page3.txt";
import Pagefour from "./txtpages/Page4.txt";
import Pagefive from "./txtpages/Page5.txt";
import Pagesix from "./txtpages/Page6.txt";
import Pageseven from "./txtpages/Page7.txt";
import Pageeight from "./txtpages/Page8.txt";
import Pagenine from "./txtpages/Page9.txt";
import Pageten from "./txtpages/Page10.txt";
import Pageeleven from "./txtpages/Page11.txt";
import Pagetwelve from "./txtpages/Page12.txt";
import Pagethirteen from "./txtpages/Page13.txt";
import Pageforteen from "./txtpages/Page14.txt";
import Pagefifteen from "./txtpages/Page15.txt";
import Pagesixteen from "./txtpages/Page16.txt";
import Pageseventeen from "./txtpages/Page17.txt";
import Pageeighteen from "./txtpages/Page18.txt";
import Pageninteen from "./txtpages/Page19.txt";
import Pagetwenty from "./txtpages/Page20.txt";
import Pagetwentyone from "./txtpages/Page21.txt";
import Pagetwentytwo from "./txtpages/Page22.txt";
import Pagetwentythree from "./txtpages/Page23.txt";
import Pagetwentyfour from "./txtpages/Page24.txt";
import Pagetwentyfive from "./txtpages/Page25.txt";
import Pagetwentysix from "./txtpages/Page26.txt";
import Pagetwentyseven from "./txtpages/Page27.txt";
import Pagetwentyeight from "./txtpages/Page28.txt";
import { useParams } from "react-router-dom";

import mixins from "../../app-services/mixins";
import Previewsteps from "./Previewsteps";
import Alertmessage from "./popups/Alertmessage";
import PreventUnload from "./PreventUnload";
import Savedbooklist from "./Savedbooklist";
import SubscriptionPlan from "../payment/checkout/SubscriptionPlan";
import useBlocker from "../../app-services/lib/useBlocker";
import Leftalert from "./popups/Leftalert";

const Worldbook = (props) => {
  const history = useHistory();
  const { id, customBookId } = useParams();
  const [preinfoCompleted, setPreinfoCompleted] = useState(false);
  const worldbookPreInfo = props.store?.book?.worldbookPreInfo;
  const answer = worldbookPreInfo?.answer;
  const [isFading, setIsFading] = useState(false);
  const [formdata, setFormdata] = useState(answer || {});
  const [images, setImages] = useState({});
  const [imagesassets, setImagesassets] = useState({});
  const [originalImage, setOriginalimage] = useState({});
  const [pages, setPages] = useState([]);
  const [showsavealert, setSavealert] = useState(false);
  const [currentopenpage, setCurrentopenpage] = useState(0);
  const [savedBook, setSavedBook] = useState([]);

  const handlePreinfoSave = () => {
    let tempformdata = {
      ...formdata,
      message_one: formdata?.message_one || "A sense of adventure",
      message_two:
        formdata?.message_two || "Hugs that warm you wherever you are",
      message_three:
        formdata?.message_three ||
        "Reminder: you are always loved and never alone",
    };
    let newanswer = { ...Object.deepClone(tempformdata) };
    let payload = Object.deepClone(newanswer);
    payload.answer = newanswer;
    setFormdata(payload.answer);
    mixins.setStore("book.worldbookPreInfo", payload, props);
    if (
      preinfoCompleted == "booktitle" ||
      preinfoCompleted == "year" ||
      preinfoCompleted == "place" ||
      preinfoCompleted == "food" ||
      preinfoCompleted == "message" ||
      preinfoCompleted == "dedication"
    ) {
      handleClick("bookinfo");
    } else if (
      preinfoCompleted == "imageone" ||
      preinfoCompleted == "imagetwo" ||
      preinfoCompleted == "imagethree" ||
      preinfoCompleted == "imagefour" ||
      preinfoCompleted == "imagefive"
    ) {
      handleClick("imageupload");
    } else {
      handleClick(false);
    }
  };
  const handlePreinfoSavesecond = (data) => {
    let tempformdata = {
      ...data,
    };
    setFormdata(data);
    let newanswer = { ...Object.deepClone(tempformdata) };
    let payload = Object.deepClone(newanswer);
    payload.answer = newanswer;
    setFormdata(payload.answer);
    mixins.setStore("book.worldbookPreInfo", payload, props);
    if (
      preinfoCompleted == "booktitle" ||
      preinfoCompleted == "year" ||
      preinfoCompleted == "place" ||
      preinfoCompleted == "food" ||
      preinfoCompleted == "message" ||
      preinfoCompleted == "dedication"
    ) {
      handleClick("bookinfo");
    } else if (
      preinfoCompleted == "imageone" ||
      preinfoCompleted == "imagetwo" ||
      preinfoCompleted == "imagethree" ||
      preinfoCompleted == "imagefour" ||
      preinfoCompleted == "imagefive"
    ) {
      handleClick("imageupload");
    } else {
      handleClick(false);
    }
  };
  const fetchPages = async () => {
    try {
      let innerwidth = window.innerWidth;
      let width = 0;
      if (innerwidth > 768) {
        width = ((innerwidth * 0.5) / 2).toFixed(0);
      } else {
        width = ((innerwidth * 1) / 2).toFixed(0);
      }
      const pageUrls = [
        // { coverone: Coverone },
        // { covertwo: Covertwo },
        { pageone: Pageone },
        { pagetwo: Pagetwo },
        { pagethree: Pagethree },
        { pagefour: Pagefour },
        { pagefive: Pagefive },
        { pagesix: Pagesix },
        { pageseven: Pageseven },
        { pageeight: Pageeight },
        { pagenine: Pagenine },
        { pageten: Pageten },
        { pageeleven: Pageeleven },
        { pagetwelve: Pagetwelve },
        { pagethirteen: Pagethirteen },
        { pageforteen: Pageforteen },
        { pagefifteen: Pagefifteen },
        { pagesixteen: Pagesixteen },
        { pageseventeen: Pageseventeen },
        { pageeighteen: Pageeighteen },
        { pageninteen: Pageninteen },
        { pagetwenty: Pagetwenty },
        { pagetwentyone: Pagetwentyone },
        { pagetwentytwo: Pagetwentytwo },
        { pagetwentythree: Pagetwentythree },
        { pagetwentyfour: Pagetwentyfour },
        { pagetwentyfive: Pagetwentyfive },
        { pagetwentysix: Pagetwentysix },
        { pagetwentyseven: Pagetwentyseven },
        { pagetwentyeight: Pagetwentyeight },
      ];

      const pagePromises = pageUrls.map(async (pageUrlObj) => {
        const pageKey = Object.keys(pageUrlObj)[0]; // Get the key name (e.g., 'pageone')
        const response = await fetch(pageUrlObj[pageKey]); // Fetch the page using the URL
        const content = await response.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, "text/html");

        const updateFontSize = (className, scale) => {
          doc.querySelectorAll(`${className}`).forEach((el) => {
            el.style.setProperty(
              "font-size",
              `${width * scale}px`,
              "important"
            );
          });
        };
        updateFontSize(".front-child-name", 0.2);
        updateFontSize(".page-three-child-name", 0.065);
        updateFontSize(".book_title", 0.015);
        updateFontSize("#custom_food_one", 0.03);
        updateFontSize("#custom_food_two", 0.03);
        updateFontSize("#custom_food_three", 0.03);
        updateFontSize("#custom_food_four", 0.03);
        updateFontSize("#custom_food_five", 0.03);
        updateFontSize("#favourite_place", 0.02);
        updateFontSize(".hangout", 0.02);
        updateFontSize(".heart", 0.025);
        updateFontSize("#message_one", 0.025);
        updateFontSize(".memories", 0.025);
        updateFontSize(".determination", 0.025);
        updateFontSize("#message_two", 0.025);
        updateFontSize(".friendship", 0.025);
        updateFontSize("#message_three", 0.025);
        updateFontSize(".page_fifteen_child_name", 0.03);
        updateFontSize(".bottle_for", 0.02);
        updateFontSize(".ingredients", 0.02);
        updateFontSize(".year_class", 0.02);
        updateFontSize("#dedication", 0.025);

        if (answer?.name) {
          const elements = doc.querySelectorAll('[id="child_name"]');
          elements.forEach((element) => {
            element.textContent = answer?.name;
          });
        }
        if (answer?.menu_one) {
          const elements = doc.querySelectorAll('[id="custom_food_one"]');
          elements.forEach((element) => {
            element.textContent = answer?.menu_one;
          });
        }
        if (answer?.menu_two) {
          const elements = doc.querySelectorAll('[id="custom_food_two"]');
          elements.forEach((element) => {
            element.textContent = answer?.menu_two;
          });
        }
        if (answer?.menu_three) {
          const elements = doc.querySelectorAll('[id="custom_food_three"]');
          elements.forEach((element) => {
            element.textContent = answer?.menu_three;
          });
        }
        if (answer?.menu_four) {
          const elements = doc.querySelectorAll('[id="custom_food_four"]');
          elements.forEach((element) => {
            element.textContent = answer?.menu_four;
          });
        }
        if (answer?.menu_five) {
          const elements = doc.querySelectorAll('[id="custom_food_five"]');
          elements.forEach((element) => {
            element.textContent = answer?.menu_five;
          });
        }
        if (answer?.fav_place) {
          const elements = doc.querySelectorAll('[id="favourite_place"]');
          elements.forEach((element) => {
            element.textContent = answer?.fav_place;
          });
        }
        if (answer?.message_one) {
          const elements = doc.querySelectorAll('[id="message_one"]');
          elements.forEach((element) => {
            element.textContent = answer?.message_one;
          });
        } else {
          const elements = doc.querySelectorAll('[id="message_one"]');
          elements.forEach((element) => {
            element.textContent = "A sense of adventure";
          });
        }
        if (answer?.message_two) {
          const elements = doc.querySelectorAll('[id="message_two"]');
          elements.forEach((element) => {
            element.textContent = answer?.message_two;
          });
        } else {
          const elements = doc.querySelectorAll('[id="message_two"]');
          elements.forEach((element) => {
            element.textContent = "Hugs that warm you wherever you are";
          });
        }
        if (answer?.message_three) {
          const elements = doc.querySelectorAll('[id="message_three"]');
          elements.forEach((element) => {
            element.textContent = answer?.message_three;
          });
        } else {
          const elements = doc.querySelectorAll('[id="message_three"]');
          elements.forEach((element) => {
            element.textContent =
              "Reminder: you are always loved and never alone";
          });
        }
        if (answer?.year) {
          const elements = doc.querySelectorAll('[id="custom_year"]');
          elements.forEach((element) => {
            element.innerHTML = answer?.year;
          });
        }
        if (answer?.book_title) {
          const elements = doc.querySelectorAll('[id="book_title"]');
          elements.forEach((element) => {
            element.innerHTML = answer?.book_title;
          });
        }
        if (answer?.dedication) {
          const elements = doc.querySelectorAll('[id="dedication"]');
          elements.forEach((element) => {
            element.innerHTML = answer?.dedication;
          });
        }
        if (imagesassets?.image_one) {
          const elements = doc.querySelectorAll('[id="image_one"]');
          elements.forEach((element) => {
            element.src = imagesassets.image_one;
            element.style.display = "block";
          });
        }
        if (imagesassets?.image_two) {
          const elements = doc.querySelectorAll('[id="image_two"]');
          elements.forEach((element) => {
            element.src = imagesassets.image_two;
            element.style.display = "block";
          });
        }
        if (imagesassets?.image_three) {
          const elements = doc.querySelectorAll('[id="image_three"]');
          elements.forEach((element) => {
            element.src = imagesassets.image_three;
            element.style.display = "block";
          });
        }
        if (imagesassets?.image_four) {
          const elements = doc.querySelectorAll('[id="image_four"]');
          elements.forEach((element) => {
            element.src = imagesassets.image_four;
            element.style.display = "block";
          });
        }
        if (imagesassets?.image_five) {
          const elements = doc.querySelectorAll('[id="image_five"]');
          elements.forEach((element) => {
            element.src = imagesassets.image_five;
            element.style.display = "block";
          });
        }
        if (imagesassets?.image_six) {
          const elements = doc.querySelectorAll('[id="image_six"]');
          elements.forEach((element) => {
            element.src = imagesassets.image_six;
            element.style.display = "block";
          });
        }
        if (imagesassets?.image_seven) {
          const elements = doc.querySelectorAll('[id="image_seven"]');
          elements.forEach((element) => {
            element.src = imagesassets.image_seven;
            element.style.display = "block";
          });
        }
        if (imagesassets?.image_eight) {
          const elements = doc.querySelectorAll('[id="image_eight"]');
          elements.forEach((element) => {
            element.src = imagesassets.image_eight;
            element.style.display = "block";
          });
        }
        if (imagesassets?.image_nine) {
          const elements = doc.querySelectorAll('[id="image_nine"]');
          elements.forEach((element) => {
            element.src = imagesassets.image_nine;
            element.style.display = "block";
          });
        }
        return { [pageKey]: new XMLSerializer().serializeToString(doc) };
      });
      const pagesContent = await Promise.all(pagePromises);
      setPages(pagesContent);
    } catch (error) {
      console.error("Error fetching pages:", error);
    }
  };
  const handleClick = (componentname) => {
    if (preinfoCompleted != componentname) {
      setIsFading(true);
      setTimeout(() => {
        setPreinfoCompleted(componentname);
        setIsFading(false);
      }, 500);
    }
  };
  const handleclose = () => {
    if (answer?.name) {
      if (
        preinfoCompleted == "booktitle" ||
        preinfoCompleted == "year" ||
        preinfoCompleted == "place" ||
        preinfoCompleted == "food" ||
        preinfoCompleted == "message" ||
        preinfoCompleted == "dedication"
      ) {
        handleClick("bookinfo");
      } else if (
        preinfoCompleted == "imageone" ||
        preinfoCompleted == "imagetwo" ||
        preinfoCompleted == "imagethree" ||
        preinfoCompleted == "imagefour" ||
        preinfoCompleted == "imagefive"
      ) {
        handleClick("imageupload");
      } else {
        handleClick(false);
      }
    } else {
      history.push(`/custom-books/product-details/${id}`);
    }
  };
  const handlebackbtn = () => {
    if (answer?.name) {
      handleClick(false);
    } else {
      history.push(`/custom-books/product-details/${id}`);
    }
  };
  useEffect(() => {
    if (answer?.name || customBookId) {
      handleClick(false);
    } else {
      handleClick("name");
    }
  }, [customBookId]);
  const getProductDetails = () => {
    api.getBookDetails(id).then((res) => {
      mixins.setStore("product", res.data.bookDetails?.product, props);
    });
  };
  useEffect(() => {
    if (customBookId) {
      getLatestBook();
    }
    if (id) {
      getProductDetails();
    }
  }, [customBookId]);
  useEffect(() => {
    fetchPages();
  }, [worldbookPreInfo, imagesassets]);

  const processPages = (pages) => {
    const parser = new DOMParser();
    return pages.reduce((acc, item) => {
      const pageKey = Object.keys(item)[0];
      let doc = parser.parseFromString(item[pageKey], "text/html");
      let bookcontent = doc.body.innerHTML;
      acc[pageKey] = { html: bookcontent, png: "", base64: "" };
      return acc;
    }, {});
  };
  const getLatestBook = () => {
    getLatestBookCall(customBookId)
      .then((res) => {
        let pages = JSON.parse(res?.savedBook);
        let imagess = fetchImageSrcs(pages);
        setImagesassets(imagess);
      })
      .catch((rej) => {
        console.log(rej);
      });
  };
  const addtocart = () => {
    let allpages = processPages(pages);
    let textdata = {
      book_title: formdata?.book_title || "",
      message_one: formdata?.message_one || "A sense of adventure",
      message_two:
        formdata?.message_two || "Hugs that warm you wherever you are ",
      message_three:
        formdata?.message_three ||
        "Reminder: you are always loved and never alone",
      name: formdata?.name || "",
      menu_one: formdata?.menu_one || "",
      menu_two: formdata?.menu_two || "",
      menu_three: formdata?.menu_three || "",
      menu_four: formdata?.menu_four || "",
      menu_five: formdata?.menu_five || "",
      fav_place: formdata?.fav_place || "",
      year: formdata?.year,
      dedication: formdata?.dedication || "",
    };
    const formData = new FormData();
    // Append images

    if (images?.image_one) {
      formData.append(
        "worldbook_img_14",
        mixins.normalizeImageExtension(images.image_one)
      );
    }
    if (images?.image_two) {
      formData.append(
        "worldbook_img_19_1",
        mixins.normalizeImageExtension(images.image_two)
      );
    }
    if (images?.image_three) {
      formData.append(
        "worldbook_img_19_2",
        mixins.normalizeImageExtension(images.image_three)
      );
    }
    if (images?.image_four) {
      formData.append(
        "worldbook_img_20_1",
        mixins.normalizeImageExtension(images.image_four)
      );
    }
    if (images?.image_five) {
      formData.append(
        "worldbook_img_20_2",
        mixins.normalizeImageExtension(images.image_five)
      );
    }
    if (images?.image_six) {
      formData.append(
        "worldbook_img_21_1",
        mixins.normalizeImageExtension(images.image_six)
      );
    }
    if (images?.image_seven) {
      formData.append(
        "worldbook_img_21_2",
        mixins.normalizeImageExtension(images.image_seven)
      );
    }
    if (images?.image_eight) {
      formData.append(
        "worldbook_img_22_1",
        mixins.normalizeImageExtension(images.image_eight)
      );
    }
    if (images?.image_nine) {
      formData.append(
        "worldbook_img_22_2",
        mixins.normalizeImageExtension(images.image_nine)
      );
    }
    if (images?.image_one) {
      formData.append(
        "file",
        mixins.normalizeImageExtension(images.image_one) || null
      );
    }

    formData.append("bookId", id || "");
    formData.append("savedBook", JSON.stringify(allpages) || "");
    formData.append("option", "dynamic");
    formData.append("sessionId", localStorage.getItem("sessionID") || null);
    formData.append("type", "worldbook");
    formData.append("attributes", JSON.stringify(textdata) || "");
    formData.append("customcharacterpng", null);
    formData.append("firstname", formdata?.name || "");
    formData.append("lastname", null);
    formData.append("dedication", formdata?.dedication || "");
    formData.append("gender", null);

    let totalSize = 0;
    for (let pair of formData.entries()) {
      totalSize += new TextEncoder().encode(pair[1]).length;
    }

    mixins.checkLogin(history).then(() => {
      if (customBookId) {
        api.updateSelfPublish(formData, customBookId).then((responce) => {
          setOriginalimage({});
          getLatestBookCall(responce.data.id)
            .then((res) => {
              addtoCart(res);
            })
            .catch((rej) => {
              console.log(rej);
            });
        });
      } else {
        api.createSelfPublish(formData).then((responce) => {
          setOriginalimage({});
          setFormdata({});
          mixins.reFectchSavedBookDetails(props);
          // history.push(history.location.pathname + `/${responce.data.id}`);
          addtoCart(responce.data);
          // setTimeout(() => {
          //   getLatestBookCall(responce.data.id)
          //     .then((res) => {
          //       addtoCart(responce);
          //     })
          //     .catch((rej) => {
          //       console.log(rej);
          //     });
          // }, 500);
        });
      }
    });
  };
  const getLatestBookCall = (id) => {
    return new Promise((resolve, reject) => {
      api
        .getSelfPublish(id)
        .then((response) => {
          if (response && response.data) {
            let responcedata = response.data;
            let arrtibutes = JSON.parse(responcedata?.attributes);
            let updatedyear = "";
            if (arrtibutes?.year) {
              updatedyear = arrtibutes?.year;
            }
            let updatedattributes = { ...arrtibutes, year: updatedyear };
            let newanswer = { ...Object.deepClone(updatedattributes) };
            let payload = Object.deepClone(newanswer);
            payload.answer = newanswer;
            setFormdata(payload.answer);
            mixins.setStore("book.worldbookPreInfo", payload, props);
            setTimeout(() => {
              resolve(responcedata);
            }, 0);
          } else {
            resolve(this.state.savedBook);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const addtoCart = (responce) => {
    if (localStorage.getItem("editmode") === "true") {
      api
        .updateToCart(
          {
            promotionId: null,
            productId: props.store.product.id,
            quantity: 1,
            price: props.store.product.actualPrice,
            discount: null,
            bookCover: null,
            customProductId: props.store.cartdetails?.customProductId,
          },
          props.store.cartdetails?.id
        )
        .then((res) => {
          localStorage.setItem("editmode", false);
          if (res && res.statusCode === 200) {
            history.push("/checkout-page");
          } else {
            alert("Something went wrong please try again later..");
          }
        });
    } else {
      // console.log("addding to cart");
      api
        .addToCart({
          promotionId: null,
          productId: props.store.product.id,
          quantity: 1,
          customBookId: responce?.customBook?.id,
          price: props.store.product.actualPrice,
          discount: null,
          bookCover: null,
        })
        .then((res) => {
          if (res && res.statusCode === 200) {
            history.push("/checkout-page");
            mixins.reFectchSavedBookDetails(this.props);
          } else {
            alert("Something went wrong please try again later..");
          }
        });
    }
  };

  const fetchImageSrcs = (data) => {
    const temimages = {
      image_one: "",
      image_two: "",
      image_three: "",
      image_four: "",
      image_five: "",
      image_six: "",
      image_seven: "",
      image_eight: "",
      image_nine: "",
    };

    const currentURL = window.location.href;

    const pageImagesMap = {
      pagefifteen: ["image_one"],
      pagetwenty: ["image_two", "image_three"],
      pagetwentyone: ["image_four", "image_five"],
      pagetwentytwo: ["image_six", "image_seven"],
      pagetwentythree: ["image_eight", "image_nine"],
    };

    for (const key in data) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(data[key], "text/html");
      const imageIds = pageImagesMap[key] || [];
      imageIds.forEach((imgId) => {
        const img = doc.getElementById(imgId);
        temimages[imgId] = img && img.src === currentURL ? "" : img?.src || "";
      });
    }

    return temimages;
  };

  const addvalidation = () => {
    if (!imagesassets?.image_one) {
      setPreinfoCompleted("imageone");
      return false;
    } else {
      return true;
    }
  };

  const handleclosetemp = () => {
    setSavealert(true);
  };

  const savebookforlater = () => {
    let allpages = processPages(pages);
    let textdata = {
      book_title: formdata?.book_title || "",
      message_one: formdata?.message_one || "A sense of adventure",
      message_two:
        formdata?.message_two || "Hugs that warm you wherever you are ",
      message_three:
        formdata?.message_three ||
        "Reminder: you are always loved and never alone",
      name: formdata?.name || "",
      menu_one: formdata?.menu_one || "",
      menu_two: formdata?.menu_two || "",
      menu_three: formdata?.menu_three || "",
      menu_four: formdata?.menu_four || "",
      menu_five: formdata?.menu_five || "",
      fav_place: formdata?.fav_place || "",
      year: formdata?.year,
      dedication: formdata?.dedication || "",
    };
    const formData = new FormData();
    // Append images
    if (images?.image_one) {
      formData.append("worldbook_img_14", images.image_one);
    }
    if (images?.image_two) {
      formData.append("worldbook_img_19_1", images.image_two);
    }
    if (images?.image_three) {
      formData.append("worldbook_img_19_2", images.image_three);
    }
    if (images?.image_four) {
      formData.append("worldbook_img_20_1", images.image_four);
    }
    if (images?.image_five) {
      formData.append("worldbook_img_20_2", images.image_five);
    }
    if (images?.image_six) {
      formData.append("worldbook_img_21_1", images.image_six);
    }
    if (images?.image_seven) {
      formData.append("worldbook_img_21_2", images.image_seven);
    }
    if (images?.image_eight) {
      formData.append("worldbook_img_22_1", images.image_eight);
    }
    if (images?.image_nine) {
      formData.append("worldbook_img_22_2", images.image_nine);
    }
    if (images?.image_one) {
      formData.append("file", images.image_one || null);
    }

    formData.append("bookId", id || "");
    formData.append("savedBook", JSON.stringify(allpages) || "");
    formData.append("option", "dynamic");
    formData.append("sessionId", localStorage.getItem("sessionID") || null);
    formData.append("type", "worldbook");
    formData.append("attributes", JSON.stringify(textdata) || "");
    formData.append("customcharacterpng", null);
    formData.append("firstname", formdata?.name || "");
    formData.append("lastname", null);
    formData.append("dedication", formdata?.dedication || "");
    formData.append("gender", null);
    console.log("Form Data", formData);
    let totalSize = 0;
    for (let pair of formData.entries()) {
      totalSize += new TextEncoder().encode(pair[1]).length;
    }
    mixins.checkLogin(history).then(() => {
      if (customBookId) {
        api.updateSelfPublish(formData, customBookId).then((responce) => {
          setOriginalimage({});
          getLatestBookCall(responce.data.id)
            .then((res) => {
              getAllSaveForLaterDetails();
            })
            .catch((rej) => {
              console.log(rej);
            });
        });
      } else {
        api.createSelfPublish(formData).then((responce) => {
          setOriginalimage({});
          setFormdata({});
          mixins.reFectchSavedBookDetails(props);
          history.push(history.location.pathname + `/${responce.data.id}`);
          setTimeout(() => {
            getLatestBookCall(responce.data.id)
              .then((res) => {
                getAllSaveForLaterDetails();
              })
              .catch((rej) => {
                console.log(rej);
              });
          }, 500);
        });
      }
    });
  };
  const getAllSaveForLaterDetails = () => {
    api.getAllSaveforLater().then((res) => {
      if (res && res.statusCode === 200 && res.data) {
        let respData2 = res.data.savedBooks;

        let worldbook = respData2.filter(
          (item) => item?.customBook.customBookType == "worldbook"
        );
        setSavedBook(worldbook);
      }
    });
  };
  const deleteCartitem = (id) => {
    api.deleteSubscriptionItem(id).then((res) => {
      if (res && res.statusCode === 200) {
        getAllSaveForLaterDetails();
      }
    });
  };
  useEffect(() => {
    getAllSaveForLaterDetails();
  }, []);

  const handletempsave = () => {
    let button = document.getElementById("action-btn");
    button.click();
  };

  return (
    <PreventUnload formdata={formdata} imagesassets={originalImage}>
      <div className="worldbook">
        <Worldbookview
          answer={answer}
          pages={pages}
          currentopenpage={currentopenpage}
        />
        <Previewsteps setCurrentopenpage={setCurrentopenpage} />
        {!preinfoCompleted && (
          <>
            <Stepbar
              name={answer?.name}
              handleopen={handleClick}
              preinfoCompleted={preinfoCompleted}
              addtocart={addtocart}
              handlePreinfoSave={handlePreinfoSave}
              savebookforlater={savebookforlater}
              handletempsave={handletempsave}
            />
          </>
        )}
        {savedBook.length > 0 && (
          <Savedbooklist
            savedBook={savedBook}
            deleteCartitem={deleteCartitem}
            formdata={formdata}
            imagesassets={originalImage}
          />
        )}

        {preinfoCompleted && (
          <Modelwrapper open={preinfoCompleted} handleClose={handleclose}>
            <div
              className={`form-container ${isFading ? "fade-out" : "fade-in"}`}
            >
              {preinfoCompleted == "name" && (
                <Firstname
                  formdata={formdata}
                  setFormdata={setFormdata}
                  handlePreinfoSave={handlePreinfoSave}
                  handlebackbtn={handlebackbtn}
                />
              )}
              {preinfoCompleted == "bookinfo" && (
                <Customisebook
                  formdata={formdata}
                  setFormdata={setFormdata}
                  handlePreinfoSave={handlePreinfoSave}
                  handlebackbtn={handlebackbtn}
                  handleClick={handleClick}
                />
              )}
              {preinfoCompleted == "booktitle" && (
                <Booktitle
                  formdata={formdata}
                  setFormdata={setFormdata}
                  handlePreinfoSave={handlePreinfoSave}
                  handlebackbtn={handlebackbtn}
                  handlePreinfoSavesecond={handlePreinfoSavesecond}
                />
              )}
              {preinfoCompleted == "year" && (
                <Yearset
                  formdata={formdata}
                  setFormdata={setFormdata}
                  handlePreinfoSave={handlePreinfoSave}
                  handlebackbtn={handlebackbtn}
                  handlePreinfoSavesecond={handlePreinfoSavesecond}
                />
              )}
              {preinfoCompleted == "place" && (
                <Favouriteplace
                  formdata={formdata}
                  setFormdata={setFormdata}
                  handlePreinfoSave={handlePreinfoSave}
                  handlebackbtn={handlebackbtn}
                />
              )}
              {preinfoCompleted == "food" && (
                <Menuitems
                  formdata={formdata}
                  setFormdata={setFormdata}
                  handlePreinfoSave={handlePreinfoSave}
                  handlebackbtn={handlebackbtn}
                />
              )}
              {preinfoCompleted == "message" && (
                <Inepirationalmessage
                  formdata={formdata}
                  setFormdata={setFormdata}
                  handlePreinfoSave={handlePreinfoSave}
                  handlebackbtn={handlebackbtn}
                />
              )}
              {preinfoCompleted == "dedication" && (
                <Dedication
                  formdata={formdata}
                  setFormdata={setFormdata}
                  handlePreinfoSave={handlePreinfoSave}
                  handlebackbtn={handlebackbtn}
                />
              )}
              {preinfoCompleted == "imageupload" && (
                <Imageupload
                  imagesassets={imagesassets}
                  formdata={formdata}
                  setFormdata={setFormdata}
                  handlePreinfoSave={handlePreinfoSave}
                  handlebackbtn={handlebackbtn}
                  handleClick={handleClick}
                  originalImage={originalImage}
                  setOriginalimage={setOriginalimage}
                />
              )}
              {preinfoCompleted == "imageone" && (
                <Uploadone
                  formdata={formdata}
                  images={images}
                  setImages={setImages}
                  imagesassets={imagesassets}
                  setImagesassets={setImagesassets}
                  handlePreinfoSave={handlePreinfoSave}
                  handlebackbtn={handlebackbtn}
                  originalImage={originalImage}
                  setOriginalimage={setOriginalimage}
                />
              )}
              {preinfoCompleted == "imagetwo" && (
                <Uploadtwo
                  images={images}
                  setImages={setImages}
                  imagesassets={imagesassets}
                  setImagesassets={setImagesassets}
                  handlePreinfoSave={handlePreinfoSave}
                  handlebackbtn={handlebackbtn}
                  originalImage={originalImage}
                  setOriginalimage={setOriginalimage}
                />
              )}
              {preinfoCompleted == "imagethree" && (
                <Uploadthree
                  formdata={formdata}
                  images={images}
                  setImages={setImages}
                  imagesassets={imagesassets}
                  setImagesassets={setImagesassets}
                  handlePreinfoSave={handlePreinfoSave}
                  handlebackbtn={handlebackbtn}
                  originalImage={originalImage}
                  setOriginalimage={setOriginalimage}
                />
              )}
              {preinfoCompleted == "imagefour" && (
                <Uploadfour
                  images={images}
                  setImages={setImages}
                  imagesassets={imagesassets}
                  setImagesassets={setImagesassets}
                  handlePreinfoSave={handlePreinfoSave}
                  handlebackbtn={handlebackbtn}
                  originalImage={originalImage}
                  setOriginalimage={setOriginalimage}
                />
              )}
              {preinfoCompleted == "imagefive" && (
                <Uploadfive
                  images={images}
                  setImages={setImages}
                  imagesassets={imagesassets}
                  setImagesassets={setImagesassets}
                  handlePreinfoSave={handlePreinfoSave}
                  handlebackbtn={handlebackbtn}
                  originalImage={originalImage}
                  setOriginalimage={setOriginalimage}
                />
              )}
            </div>
            {answer?.name && (
              <Stepbar
                name={answer?.name}
                handleopen={handleClick}
                preinfoCompleted={preinfoCompleted}
                handlePreinfoSave={handlePreinfoSave}
                addtocart={addtocart}
                handletempsave={handletempsave}
              />
            )}
          </Modelwrapper>
        )}
      </div>
    </PreventUnload>
  );
};

export default connect(Worldbook);
