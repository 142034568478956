import React, { useEffect, useRef, useState } from "react";
import titleimg from "../../../../assets/images/worldbook/cover-year.png";

const Yearset = (props) => {
  const {
    formdata,
    setFormdata,
    handlePreinfoSave = () => {},
    handlebackbtn = () => {},
    handlePreinfoSavesecond = () => {},
  } = props;

  const [alert, setAlert] = useState(null);
  const [line1, setLine1] = useState(null);
  const [line2, setLine2] = useState(null);
  const [limit1, setLimit1] = useState(20);
  const [limit2, setLimit2] = useState(20);
  const nameRef1 = useRef();

  const handleChange = (key, value) => {
    setFormdata((prev) => ({ ...prev, [key]: value }));
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    if (!line1?.trim()) {
      setAlert("Field is required.");
      nameRef1.current.focus();
    } else {
      let year = `<div>${line1 ? `<p>${line1}</p>` : ""}${
        line2 ? `<p>${line2}</p>` : ""
      }</div>`;
      let newformdata = { ...formdata, year: year };
      handlePreinfoSavesecond(newformdata);
    }
  };

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(null);
      }, 2000);
    }
  }, [alert]);

  useEffect(() => {
    if (line1?.trim()) {
      setLimit1(20 - line1?.length || 0);
    } else {
      setLimit1(20);
    }
    if (line2?.trim()) {
      setLimit2(20 - line2?.length || 0);
    } else {
      setLimit2(20);
    }
  }, [line1, line2]);

  useEffect(() => {
    if (formdata?.year) {
      let parray = mixins.extractParagraphs(formdata.year);
      setLine1(parray?.[0] || "");
      setLine2(parray?.[1] || "");
    }
  }, [formdata?.year]);

  return (
    <div id="msform">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="picture">
                <img
                  src={
                    "https://e-books-customization.s3.ap-southeast-1.amazonaws.com/e-book-api/thisisyourworld/21.png"
                  }
                  alt=""
                />
                <div className="modal_year_container">
                  <div className="year_class">
                    <span id="custom_year">
                      {line1 || `Custom Text Here`} <br />
                      {line2 || `eg Graduating Class of 202X`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="rt-content">
                <h4>What milestone is being celebrated?</h4>
                <p>
                  Some fun examples:{" "}
                  <i>
                    Graduating year of 2024, Internship completed 2020, New job
                    starts 2026, New School, Moving Abroad, Australia 2028
                  </i>
                </p>
                <div className="form-group position-relative my-3">
                  <input
                    ref={nameRef1}
                    type="text"
                    maxLength={20}
                    className="m-0"
                    placeholder="Please enter title of Line 1"
                    value={line1 || ""}
                    onChange={(e) => {
                      mixins.preventEmoji(e);
                      setLine1(e.target.value);
                    }}
                  />
                  <span className="limit">{limit1}</span>
                  {alert && (
                    <span
                      style={{
                        color: "red",
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        fontSize: "12px",
                      }}
                    >
                      {alert}
                    </span>
                  )}
                </div>
                <div className="form-group my-3">
                  <input
                    type="text"
                    maxLength={20}
                    className="m-0"
                    placeholder="Please enter title of Line 2"
                    value={line2 || ""}
                    onChange={(e) => {
                      mixins.preventEmoji(e);
                      setLine2(e.target.value);
                    }}
                  />
                  <span className="limit">{limit2}</span>
                </div>
                <div className="save-btn">
                  <button
                    style={{
                      display: "none",
                    }}
                    id="action-btn"
                    className="action-button"
                    onClick={handlesubmit}
                  >
                    Save & Back to List
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(Yearset);
