import React from "react";
import thumbimgone from "./../../assets/images/worldbook/thumb-1.jpg";
import thumbimgtwo from "./../../assets/images/worldbook/thumb-2.jpg";
import thumbimgthree from "./../../assets/images/worldbook/booktitle_thumb.png";
import thumbimgfour from "./../../assets/images/worldbook/river-thumb.png";
import thumbimgfive from "./../../assets/images/worldbook/thumb-5.jpg";
import thumbmenuicon from "./../../assets/images/worldbook/menu-thumb.png";
import calenderthumb from "./../../assets/images/worldbook/calender-thumb.png";
import thoughtthumb from "./../../assets/images/worldbook/thought-thumb.png";
import thumbbag from "./../../assets/images/worldbook/thumb-bag.png";
import thumspray from "./../../assets/images/worldbook/bottlespray-thumb.png";
import thumbframe from "./../../assets/images/worldbook/thumb-frame.png";
import lastframe from "./../../assets/images/worldbook/last-frame.png";
import pagetwentyone from "./../../assets/images/worldbook/thum_twently_one.png";
import firstthumb from "./../../assets/images/worldbook/first_thumb.png";

const Previewsteps = ({ setCurrentopenpage }) => {
  return (
    <>
      <div className="col-12">
        {/* <h2 className="fs-title">Preview your personalised book</h2> */}
        <div className="book-thumbnail">
          <ul>
            <li
              onClick={() => {
                setCurrentopenpage(0);
              }}
            >
              <img src={firstthumb} alt="" />
            </li>
            <li
              onClick={() => {
                setCurrentopenpage(2);
              }}
            >
              <img src={thumbimgone} alt="" />
            </li>
            <li
              onClick={() => {
                setCurrentopenpage(7);
              }}
            >
              <img src={thumbimgthree} alt="" />
            </li>
            <li
              onClick={() => {
                setCurrentopenpage(9);
              }}
            >
              <img src={thumbmenuicon} alt="" />
            </li>
            <li
              onClick={() => {
                setCurrentopenpage(12);
              }}
            >
              <img src={thumbimgfour} alt="" />
            </li>
            <li
              onClick={() => {
                setCurrentopenpage(13);
              }}
            >
              <img src={thoughtthumb} alt="" />
            </li>
            <li
              onClick={() => {
                setCurrentopenpage(14);
              }}
            >
              <img src={thumbbag} alt="" />
            </li>
            <li
              onClick={() => {
                setCurrentopenpage(18);
              }}
            >
              <img src={thumspray} alt="" />
            </li>
            <li
              onClick={() => {
                setCurrentopenpage(19);
              }}
            >
              <img src={thumbframe} alt="" />
            </li>
            <li
              onClick={() => {
                setCurrentopenpage(20);
              }}
            >
              <img src={calenderthumb} alt="" />
            </li>
            <li
              onClick={() => {
                setCurrentopenpage(21);
              }}
            >
              <img src={pagetwentyone} alt="" />
            </li>
            <li
              onClick={() => {
                setCurrentopenpage(22);
              }}
            >
              <img src={thumbimgtwo} alt="" />
            </li>
            <li
              onClick={() => {
                setCurrentopenpage(27);
              }}
            >
              <img src={lastframe} alt="" />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Previewsteps;
