import React from "react";
import { MdCloudUpload } from "react-icons/md";
import checkimage from "../../../../assets/images/worldbook/check.svg";
import { FaCheck } from "react-icons/fa";
import { IoImageOutline } from "react-icons/io5";

const Nitification = () => {
  return (
    <div className="text-left mb-3">
      <p className="font-small-notification">
        <MdCloudUpload /> Upload your photo. <FaCheck /> Check your photo is displayed correctly (on the
        left). <IoImageOutline /> If you wish, click on Edit Image. Use the easy tool
        to adjust the displayed area of your photo, or do simple cropping. Don’t
        forget to Save.
      </p>
    </div>
  );
};

export default Nitification;
