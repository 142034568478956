import React from "react";
import { useParams } from "react-router-dom";

const Stepbar = (props) => {
  const { id, customBookId } = useParams();
  const {
    name,
    handleopen = () => {},
    preinfoCompleted,
    addtocart = () => {},
    handlePreinfoSave = () => {},
    savebookforlater = () => {},
    handletempsave = () => {},
  } = props;
  return (
    <div className="container worldbook-stepbar">
      <div className="row no-gutters space">
        <div className="col-12">
          <div className="d-flex align-item-center custom-flex">
            <div
              className={`action-button ${
                preinfoCompleted == "name" ? "disabled" : ""
              }`}
              onClick={() => {
                handleopen("name");
              }}
            >
              Edit Name
            </div>
            <div
              className={`action-button ${
                preinfoCompleted == "bookinfo" ? "disabled" : ""
              }`}
              onClick={() => {
                handleopen("bookinfo");
              }}
            >
              Customise Your Story
            </div>
            <div
              className={`action-button ${
                preinfoCompleted == "imageupload" ? "disabled" : ""
              }`}
              onClick={() => {
                handleopen("imageupload");
              }}
            >
              Upload Your Photos
            </div>
            {preinfoCompleted ? (
              <>
                {(name && preinfoCompleted == "name") ||
                preinfoCompleted == "bookinfo" ||
                preinfoCompleted == "imageupload" ? (
                  <div
                    className="action-button"
                    onClick={() => {
                      handlePreinfoSave();
                    }}
                  >
                    Save & Preview
                  </div>
                ) : (
                  <>
                    {preinfoCompleted != "name" && (
                      <button
                        className="action-button bg-custom-dark"
                        onClick={() => {
                          handletempsave();
                        }}
                      >
                        Save & Back to List
                      </button>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {/* <div
                  className={`action-button ${
                    preinfoCompleted ? "disabled" : ""
                  }`}
                  onClick={savebookforlater}
                >
                  Save Book
                </div> */}
                <div
                  className={`action-button ${
                    preinfoCompleted ? "disabled" : ""
                  }`}
                  onClick={addtocart}
                >
                  Save & Add to Cart
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(Stepbar);
