import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useBlocker from "../../app-services/lib/useBlocker";
import Leftalert from "./popups/Leftalert";

const PreventUnload = ({ formdata, imagesassets, children }) => {
  const { id, customBookId } = useParams();
  const shouldBlockNavigation = useMemo(() => {
    if (customBookId) {
      return !!(
        imagesassets?.image_one ||
        imagesassets?.image_two ||
        imagesassets?.image_three ||
        imagesassets?.image_four ||
        imagesassets?.image_five ||
        imagesassets?.image_six ||
        imagesassets?.image_seven ||
        imagesassets?.image_eight ||
        imagesassets?.image_nine
      );
    } else {
      return !!(
        imagesassets?.image_one ||
        imagesassets?.image_two ||
        imagesassets?.image_three ||
        imagesassets?.image_four ||
        imagesassets?.image_five ||
        imagesassets?.image_six ||
        imagesassets?.image_seven ||
        imagesassets?.image_eight ||
        imagesassets?.image_nine ||
        formdata?.name
      );
    }
  }, [formdata, imagesassets, customBookId]);

  const [debouncedShouldBlock, setDebouncedShouldBlock] = useState(
    shouldBlockNavigation
  );
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedShouldBlock(shouldBlockNavigation);
    }, 500);

    return () => clearTimeout(handler);
  }, [shouldBlockNavigation]);
  const { confirmNavigation, cancelNavigation, nextLocation } = useBlocker(
    debouncedShouldBlock,
    "You have unsaved changes. Are you sure you want to leave?"
  );
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (customBookId) {
        if (
          imagesassets?.image_one ||
          imagesassets?.image_two ||
          imagesassets?.image_three ||
          imagesassets?.image_four ||
          imagesassets?.image_five ||
          imagesassets?.image_six ||
          imagesassets?.image_seven ||
          imagesassets?.image_eight ||
          imagesassets?.image_nine
        ) {
          event.preventDefault();

          event.returnValue =
            "You have unsaved changes. Are you sure you want to leave?";
        }
      } else {
        if (
          imagesassets?.image_one ||
          imagesassets?.image_two ||
          imagesassets?.image_three ||
          imagesassets?.image_four ||
          imagesassets?.image_five ||
          imagesassets?.image_six ||
          imagesassets?.image_seven ||
          imagesassets?.image_eight ||
          imagesassets?.image_nine ||
          formdata?.name
        ) {
          event.preventDefault();

          event.returnValue =
            "You have unsaved changes. Are you sure you want to leave?";
        }
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [imagesassets]);

  return (
    <>
      {children}

      {nextLocation && shouldBlockNavigation && (
        <Leftalert
          open={nextLocation}
          handleyess={confirmNavigation}
          handleno={() => {
            cancelNavigation();
          }}
        />
      )}
    </>
  );
};

export default PreventUnload;
