import React, { useEffect, useRef, useState } from "react";
import titleimg from "../../../../assets/images/worldbook/left-dedication.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Dedication = (props) => {
  const {
    formdata,
    setFormdata,
    handlePreinfoSave = () => {},
    handlebackbtn = () => {},
  } = props;
  const [alert, setAlert] = useState(null);
  const [limit, setLimit] = useState(450);
  const [widthdata, setWidthdata] = useState(0);
  const nameRef = useRef();
  const handleChange = (val) => {
    setFormdata(val);
  };
  const handlesubmit = (e) => {
    e.preventDefault();
    handlePreinfoSave();
  };
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(null);
      }, 2000);
    }
  }, [alert]);
  useEffect(() => {
    if (formdata?.dedication) {
      let count = stripHtmlAndCountText(formdata?.dedication);
      setLimit(450 - count);
    } else {
      setLimit(450);
    }
  }, [formdata]);

  const stripHtmlAndCountText = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    const textNodes = Array.from(tempDiv.childNodes)
      .filter(
        (node) =>
          node.nodeType === Node.TEXT_NODE ||
          (node.nodeType === Node.ELEMENT_NODE && node.tagName !== "BR")
      )
      .map((node) => node.textContent.trim())
      .filter((text) => text.length > 0);
    return textNodes.join(" ").length;
  };

  const handleEditData = (event) => {
    const imgWidth = event.target.width;
    console.log("Width", event.target.width);
    const scaleFactor = 0.025;
    if (imgWidth) {
      setWidthdata(imgWidth * 0.6);
      document.querySelectorAll(".dedication-content p").forEach((el) => {
        el.style.setProperty(
          "font-size",
          `${imgWidth * scaleFactor}px`,
          "important"
        );
      });
    }
  };

  const cleanHeadingsToP = (content) => {
    for (let i = 1; i <= 6; i++) {
      content = content.replace(new RegExp(`<h${i}[^>]*>`, "g"), "<p>");
      content = content.replace(new RegExp(`</h${i}>`, "g"), "</p>");
    }
    content = content.replace(/\s*(id|class)="[^"]*"/g, "");
    content = content.replace(/<p>\s*<\/p>/g, "");
    content = content.replace(/\n\s*\n/g, "\n");
    return content.trim();
  };

  return (
    <div id="msform">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="picture ">
                <div className="dedication-container">
                  <img
                    src={titleimg}
                    alt=""
                    onLoad={(event) => {
                      handleEditData(event);
                    }}
                  />
                  <div
                    className="dedciation-top"
                    style={{
                      width: `${widthdata}px`,
                      margin: "auto",
                      maxHeight: `${widthdata}px`,
                    }}
                  >
                    <div
                      className="dedication-content"
                      dangerouslySetInnerHTML={{ __html: formdata?.dedication }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="rt-content">
                <h4>
                  More space for a special message or leave it blank for
                  autographs
                </h4>
                <ReactQuill
                  theme="snow"
                  value={formdata?.dedication || ""}
                  onChange={(content) => {
                    let updatedContent = cleanHeadingsToP(content);
                    let new_content =
                      mixins.preventEmojicontent(updatedContent);
                    console.log(updatedContent);
                    let count = stripHtmlAndCountText(new_content);
                    if (count <= 450) {
                      handleChange({ ...formdata, dedication: new_content });
                    } else {
                      handleChange({ ...formdata });
                    }
                  }}
                  placeholder="A special message"
                  modules={{
                    toolbar: false, // Disable the toolbar
                    clipboard: {
                      matchVisual: false, // Optional: prevent visual formatting
                    },
                  }}
                  className="editor"
                />
                <span className="limit">{limit}</span>
                {alert && (
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    {alert}
                  </span>
                )}

                <div className="save-btn">
                  <button
                    style={{
                      display: "none",
                    }}
                    id="action-btn"
                    className="action-button"
                    onClick={handlesubmit}
                  >
                    Save & Back to List
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(Dedication);
