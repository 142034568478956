import React, { useEffect, useRef, useState } from "react";
import titleimg from "../../../../assets/images/worldbook/favourite-place.jpg";

const Favouriteplace = (props) => {
  const {
    formdata,
    setFormdata,
    handlePreinfoSave = () => {},
    handlebackbtn = () => {},
  } = props;
  const [alert, setAlert] = useState(null);
  const [limit, setLimit] = useState(26);
  const nameRef = useRef();
  const handleChange = (val) => {
    setFormdata(val);
  };
  const handlesubmit = (e) => {
    e.preventDefault();
    if (!formdata?.fav_place?.trim()) {
      setAlert("Field is required.");
      nameRef.current.focus();
    } else {
      handlePreinfoSave();
    }
  };
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(null);
      }, 2000);
    }
  }, [alert]);
  useEffect(() => {
    if (formdata?.fav_place) {
      setLimit(26 - formdata?.fav_place?.length || 0);
    } else {
      setLimit(26);
    }
  }, [formdata]);
  return (
    <div id="msform">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="picture">
                <img
                  src={
                    "https://e-books-customization.s3.ap-southeast-1.amazonaws.com/e-book-api/thisisyourworld/final/13.png"
                  }
                  alt=""
                />
                <div className="hangout_container">
                  <p id="favourite_place">
                    {formdata?.fav_place || "Custom name of favourite place"}
                  </p>
                  <p class="hangout">
                    Our
                    <br />
                    favourite
                    <br />
                    hangout
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="rt-content">
                <h4 className="pb-2">
                  Where is the place that is The Spot for your friend(s) or
                  family?{" "}
                </h4>
                <div className="form-group position-relative">
                  <p className="pb-2">
                    Think of a familiar place. Somewhere instantly recognisable.
                  </p>
                  <input
                    ref={nameRef}
                    type="text"
                    maxLength={26}
                    placeholder="Please enter favourite place"
                    className="m-0"
                    value={formdata?.fav_place || ""}
                    onChange={(e) => {
                      mixins.preventEmoji(e);
                      handleChange({ ...formdata, fav_place: e.target.value });
                    }}
                  />
                  <span className="limit">{limit}</span>
                  {alert && (
                    <span
                      style={{
                        color: "red",
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        fontSize: "12px",
                      }}
                    >
                      {alert}
                    </span>
                  )}
                </div>

                <div className="save-btn">
                  <button
                    style={{
                      display: "none",
                    }}
                    id="action-btn"
                    className="action-button"
                    onClick={handlesubmit}
                  >
                    Save & Back to List
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(Favouriteplace);
