import React, { useEffect, useRef, useState } from "react";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";

const ImageCropper = ({ image, onCropComplete, ratio }) => {
  const cropperRef = useRef(null);
  const imageElement = useRef(null);
  const [showeditor, setShoweditor] = useState(false);
  useEffect(() => {
    if (imageElement.current) {
      const cropper = new Cropper(imageElement.current, {
        aspectRatio: ratio ? ratio : 1,
        viewMode: 1,
        scalable: false,
        rotatable: false,
        autoCrop: true,
        zoomable: false,
        cropend: handleCropComplete,
        ready: () => {
          // Ensure the crop box covers the entire image
          const cropperInstance = cropperRef.current;
          if (cropperInstance) {
            const imageData = cropperInstance.getImageData();
            const cropBoxData = {
              left: 0,
              top: 0,
              width: imageData.naturalWidth,
              height: imageData.naturalHeight,
            };
            cropperInstance.setCropBoxData(cropBoxData);
          }
        },
      });
      cropperRef.current = cropper;

      return () => {
        cropper.destroy();
      };
    }
  }, [image, showeditor]);

  const handleCropComplete = () => {
    if (cropperRef.current) {
      const croppedCanvas = cropperRef.current.getCroppedCanvas();
      const mimeType = image.match(/data:(.*?);base64,/)[1];
      if (mimeType === "image/png") {
        const base64 = croppedCanvas.toDataURL("image/png");
        const file = dataURLToFile(base64, "cropped-image.png");
        const fileSizeInMB = file.size / (1024 * 1024);

        onCropComplete(base64, file);
      } else if (mimeType === "image/jpeg" || mimeType === "image/jpg") {
        const base64 = croppedCanvas.toDataURL("image/jpeg");
        const file = dataURLToFile(base64, "cropped-image.jpg");
        const fileSizeInMB = file.size / (1024 * 1024);

        onCropComplete(base64, file);
      } else {
        console.error("Unsupported image type:", mimeType);
      }
    }
  };

  const dataURLToFile = (dataUrl, filename) => {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  return (
    <div className="editor-container">
      <div className="Editor-name">
        <div
          className="d-flex align-items-center justify-content-between header-image"
          onClick={() => {
            setShoweditor(!showeditor);
          }}
        >
          <p>Edit Image</p>
          <i
            className={showeditor ? "fa fa-chevron-up" : "fa fa-chevron-down"}
            style={{
              color: "#ffffff",
              transition: "all ease-in 0.3s",
            }}
          ></i>
        </div>
      </div>
      {showeditor && (
        <div style={{ width: "100%", height: "250px" }}>
          {image && (
            <img
              ref={imageElement}
              src={image}
              alt="Selected"
              style={{ maxWidth: "100%", height: "100%" }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ImageCropper;
