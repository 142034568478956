import React, { useState, useEffect } from "react";
import phoneblue from "../../../assets/images/phoneblue.png";
import editblue from "../../../assets/images/editblue.png";
import location from "../../../assets/images/location.png";
// import us from '../../../assets/images/us.png'
import arrow from "../../../assets/images/arrow.png";
// import master from '../../../assets/images/master.png'
// import gpay from '../../../assets/images/gpay.png'
// import apay from '../../../assets/images/apay.png'
// import visa from '../../../assets/images/visa.png'
import ppaypal from "../../../assets/images/ppaypal.png";
import back from "../../../assets/images/back.png";
import { useHistory } from "react-router-dom";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
export default connect((props) => {
  //console.log(props);
  const history = useHistory();
  const [alertFail, setAlertFail] = useState(null);
  const [loading, setLoading] = useState("none");
  const [alert, setAlert] = useState(null);
  const [codStatusdata, setCodstausdata] = useState(0);
  const codStatus = () => {
    api.getCartDetails().then((res) => {
      if (res && res.statusCode === 200 && res.data) {
        setCodstausdata(res?.data?.setting?.status);
      }
    });
  };
  useEffect(() => {
    codStatus();
  }, []);
  const cashOnDelivery = () => {
    let couponCode = null;
    let disccount = null;
    let customizedId = props.store.cart_list.cart.map((o) => {
      if (
        o.customBookDetails.customBookType == "customcharacter" ||
        o.customBookDetails.customBookType == "handprint" ||
        o.customBookDetails.customBookType == "worldbook"
      ) {
        return [
          o.customBookDetails.customBookType,
          o.customBookDetails.customizedId,
        ];
      }
    });
    //console.log(customizedId);
    if (props.store.currentCoupon || props.store.dicount) {
      couponCode = props.store.currentCoupon;
      disccount = props.store.dicount;
      //couponCodeId = props.store.seletedpromotion.id;
    } else {
      couponCode = null;
      disccount = null;
      ///couponCodeId = null;
    }
    setLoading("flex");
    api
      .cashonDeliveryMethod({
        tax: props?.store?.totaltaxamount ? 1 : 0,
        totalTax: props?.store?.gstamount,
        //gst_rate: props?.store?.contrywithgst?.gst_rate,
        subTotal: props.store.CartDetails && props.store.CartDetails.subTotal,
        userId: "",
        shipping: 0,
        shippingCharge: props.store.deliveryDetails.price,
        grandTotal: props.store && props.store.Total,
        stripeOrderId: "",
        totalDiscount: disccount,
        stripePaymentId: "",
        customizedId: customizedId ? JSON.stringify(customizedId) : null,
        paymentType: props.store.Total > 0 ? "COD" : "100% Discount",
        deliveryTypeId:
          props.store.deliveryDetails && props.store.deliveryDetails.id,
        shippingAddressId:
          props.store.fulladdress && props.store.fulladdress.id,
        promotionCode: couponCode,
        // "promotionId":couponCodeId
      })
      .then((res) => {
        setLoading("none");
        if (res && res.data && res.statusCode === 200) {
          setAlert(res.message);
          setTimeout(() => {
            setAlert(null);
          }, 3000);
          localStorage.setItem("orderId", res.data.id);
          history.push("/order-confirmation");
        } else {
          if (res && res.errorMessages) {
            setAlertFail(res.errorMessages[0]);
            setTimeout(() => {
              setAlertFail(null);
            }, 3000);
          } else {
            setAlertFail(res.message);
            setTimeout(() => {
              setAlertFail(null);
            }, 3000);
          }
        }
      });
  };

  const pushtopaypal = async () => {
    const { currentCoupon, finalAmount: subtotal, cart_list } = props.store;

    let bookitems = cart_list.cart.map((o) => ({
      name: o.product.bookTitle,
      unit_amount: {
        currency_code: "SGD",
        value: parseFloat(o.product.actualPrice).toFixed(2),
      },
      quantity: 1,
      sku: o.customBookDetails.customizedId,
    }));

    if (currentCoupon) {
      try {
        const res = await api.getcouponcode(currentCoupon);

        if (res.status === "success") {
          const coupondata = res.data;

          if (coupondata.disType === "percentage") {
            let tempitem = bookitems.map((item) => {
              let discount = (
                item.unit_amount.value *
                (coupondata.disValue / 100)
              ).toFixed(2);
              return {
                ...item,
                unit_amount: {
                  currency_code: "SGD",
                  value: (
                    parseFloat(item.unit_amount.value) - discount
                  ).toFixed(2),
                },
              };
            });

            let subtotalWithDiscount = tempitem.reduce(
              (sum, item) => sum + parseFloat(item.unit_amount.value),
              0
            );
            let restAmount = (subtotal - subtotalWithDiscount).toFixed(2);
            if (restAmount > 0 && tempitem.length > 0) {
              let lastItem = tempitem[tempitem.length - 1];
              lastItem.unit_amount.value = (
                parseFloat(lastItem.unit_amount.value) + parseFloat(restAmount)
              ).toFixed(2);
            }

            let payload = {
              subtotal: subtotal,
              item: tempitem,
            };
            mixins.setStore("bookitems", payload, props);
            setTimeout(() => history.push("paypal"), 500);
          } else {
            let numBooks = bookitems.length;
            let eachBookDiscount = (coupondata.disValue / numBooks).toFixed(2);

            let tempitem = bookitems.map((item) => ({
              ...item,
              unit_amount: {
                currency_code: "SGD",
                value: (
                  parseFloat(item.unit_amount.value) - eachBookDiscount
                ).toFixed(2),
              },
            }));

            let subtotalWithDiscount = tempitem.reduce(
              (sum, item) => sum + parseFloat(item.unit_amount.value),
              0
            );

            let restAmount = (subtotal - subtotalWithDiscount).toFixed(2);
            if (restAmount > 0 && tempitem.length > 0) {
              let lastItem = tempitem[tempitem.length - 1];
              lastItem.unit_amount.value = (
                parseFloat(lastItem.unit_amount.value) + parseFloat(restAmount)
              ).toFixed(2);
            }

            let payload = {
              subtotal: subtotalWithDiscount.toFixed(2),
              item: tempitem,
            };

            mixins.setStore("bookitems", payload, props);
            setTimeout(() => history.push("paypal"), 500);
          }
        }
      } catch (error) {
        console.error("Error fetching coupon:", error);
      }
    } else {
      let subtotalWithDiscount = bookitems.reduce(
        (sum, item) => sum + parseFloat(item.unit_amount.value),
        0
      );

      let payload = {
        subtotal: subtotalWithDiscount.toFixed(2),
        item: bookitems,
      };

      mixins.setStore("bookitems", payload, props);
      setTimeout(() => history.push("paypal"), 500);
    }
  };

  return (
    <div className="deliverycard">
      {alertFail && (
        <Alert severity="error" variant="filled">
          <strong>{alertFail}</strong>
        </Alert>
      )}
      {alert && (
        <Alert severity="success" variant="filled">
          <strong>{alert}</strong>
        </Alert>
      )}
      <div className="delivery-address-container">
        <div className="delivery-address-container-row">
          <div
            className="delivery-address-container-col-left"
            style={{ fontWeight: "bold", fontFamily: "Quicksand-Bold" }}
          >
            Deliver to : {props.store.fulladdress.firstName + " "}
            {props.store.fulladdress.lastName}
          </div>
          {/* <div className='delivery-address-container-col'><label><img src={phoneblue} />Call Customer</label></div> */}
        </div>
        <div className="delivery-address-container-row">
          <div className="delivery-address-container-col-left">
            <div className="delivery-address-container-col-left-row">
              <div className="delivery-address-container-col-left-colm">
                Address:
              </div>
              <div className="delivery-address-container-col-left-col">
                <img src={location} className="location" />
                {props.store.fulladdress.address1}{" "}
                {props.store.fulladdress.address2}{" "}
                {props.store.fulladdress.city} {props.store.fulladdress.state}{" "}
                {props.store.fulladdress.zipCode}
              </div>
            </div>
          </div>
          <div
            className="delivery-address-container-col"
            onClick={() => {
              history.push(`/edit-address/${props.store.fulladdress.id}`);
            }}
          >
            <label>
              <img src={editblue} />
              Edit Address
            </label>
          </div>
        </div>
        <div className="delivery-type-container-row">
          <div className="del-titles">Delivery Speed :</div>
          <div className="delivery-date">
            {props.store.deliveryDetails.shippingType} (Get it by{" "}
            {props.store.deliveryDetails.estimatedDelhiveryDate})
          </div>
        </div>
      </div>
      <div className="delivery-row">
        <div className="title">Shipping Details</div>
        <div className="date">Secure & Encrypted Payments!</div>
      </div>
      <div className="delivery-address-container">
        <div className="delivery-title">Debit or Credit Cards</div>
        {props.store.Total > 0 ? (
          <>
            {codStatusdata > 0 && (
              <div className="delivery-border-type-container-row">
                {/* <div className='delivery-type-radio'><input type="radio" /></div> */}
                <div className="delivery-payment-card">
                  <div
                    className="delivery-payment-card-row"
                    onClick={cashOnDelivery}
                    style={{
                      pointerEvents: loading !== "none" ? "none" : "auto",
                    }}
                  >
                    {/* <img src={visa} />
                          <img src={master} />
                          <img src={us} />
                          <img src={gpay} />
                          <img src={apay} /> */}
                    Cash on Delivery
                    <div style={{ display: loading }}>
                      <CircularProgress color="success" />
                    </div>
                  </div>
                </div>
                <div className="arrow-img" onClick={cashOnDelivery}>
                  <img src={arrow} />
                </div>
              </div>
            )}
            <div className="delivery-type-container-row">
              {/* <div className='delivery-type-radio'><input type="radio" /></div> */}
              <div className="delivery-payment-card">
                <div
                  className="delivery-payment-card-row"
                  onClick={() => {
                    pushtopaypal();
                  }}
                >
                  <img src={ppaypal} />
                </div>
              </div>
              <div
                className="arrow-img"
                onClick={() => {
                  pushtopaypal();
                }}
              >
                <img src={arrow} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="delivery-border-type-container-row border">
              {/* <div className='delivery-type-radio'><input type="radio" /></div> */}
              <div className="delivery-payment-card">
                <div
                  className="delivery-payment-card-row"
                  onClick={cashOnDelivery}
                >
                  {/* <img src={visa} />
                          <img src={master} />
                          <img src={us} />
                          <img src={gpay} />
                          <img src={apay} /> */}
                  Order Now
                  <div style={{ display: loading }}>
                    <CircularProgress color="success" />
                  </div>
                </div>
              </div>
              <div className="arrow-img" onClick={cashOnDelivery}>
                <img src={arrow} />
              </div>
            </div>
          </>
        )}
      </div>
      <div className="delivery-button-container">
        <div
          className="back-btn"
          onClick={() => {
            history.push("/delivery");
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <span>
            <img
              src={back}
              onClick={() => {
                history.push("/shipping-details");
              }}
            />
            Back to Shipping details
          </span>
        </div>
      </div>
    </div>
  );
});
